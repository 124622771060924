import { combineReducers } from "redux";
import counterReducer from "./slices/counter";
import feedbackReducer from "./slices/feedback";
import pipReducer from "./slices/pip";
import userauthReducer from "./slices/userauth";
import scorerecordReducer from "./slices/scorerecord";
import scorerecordrangeReducer from "./slices/scorerecordrange";
import monthyearReducer from "./slices/monthyear";
import tomonthyearReducer from "./slices/tomonthyear";
import reporteeReducer from "./slices/reportee";
import aprReducer from "./slices/apr";
import saprReducer from "./slices/sapr";
import maprReducer from "./slices/mapr";
import performanceReducer from "./slices/performance";
import formsettingReducer from "./slices/formsetting";
import crpRatingReducer from "./slices/continuousreview";
import annualokrratingReducer from "./slices/annualokrrating";
import quaterlyOKRsReducer from "./slices/quaterlyokr"
import pfFeedbackReducer from "./slices/pfmonthfeedback"

const rootPersistConfig = {};

const rootReducer = combineReducers({
  counter: counterReducer,
  feedback: feedbackReducer,
  pip: pipReducer,
  userauth: userauthReducer,
  scorerecord: scorerecordReducer,
  scorerecordrange: scorerecordrangeReducer,
  annualokrrating: annualokrratingReducer,
  quaterlyokrs: quaterlyOKRsReducer,
  pfmonthfeedback: pfFeedbackReducer,
  monthyear: monthyearReducer,
  tomonthyear: tomonthyearReducer,
  reportee: reporteeReducer,
  apr: aprReducer,
  sapr: saprReducer,
  mapr: maprReducer,
  performance: performanceReducer,
  continuousreview: crpRatingReducer,
  formsetting: formsettingReducer,
});

export { rootPersistConfig, rootReducer };
