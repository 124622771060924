import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config";

const initialState = {
  reportees: [],
  totalActiveEmp: null,
  loading: [],
  allEmployees: [],
};

const slice = createSlice({
  name: "reportee",
  initialState,
  reducers: {
    getLoading(state, action) {
      state.loading = action.payload;
    },
    getReportees(state, action) {
      state.reportees = action.payload;
    },
    getTotalActiveEmp(state, action) {
      state.totalActiveEmp = action.payload;
    },
    getAllEmployees(state, action) {
      state.allEmployees = action.payload;
    }
  },
});

export default slice.reducer;

export function getReporteesList() {
  return async (dispatch) => {
    try {
      const res = await axios.get(config.API_URL + "/users/reportee", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${window.localStorage.getItem("accessToken")}`,
        },

      });
      const allEmployees = await axios.get(config.API_URL + "/users/all")

      dispatch(slice.actions.getTotalActiveEmp(res.data.totalEmp));
      dispatch(slice.actions.getReportees(res.data.result));
      dispatch(slice.actions.getAllEmployees(allEmployees.data.result));
      dispatch(slice.actions.getLoading(false));
    } catch (error) {
      dispatch(slice.actions.getTotalActiveEmp(null));
      dispatch(slice.actions.getReportees([]));
      dispatch(slice.actions.getLoading(false));
    }
  };
}
