import React, { useEffect } from "react";
import * as axios from "axios";
import { config } from "../../config";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { callData, callLoggedIn } from "../../redux/slices/userauth";
import { GoogleLogin } from "@react-oauth/google";

let checkUserSession = async () => {
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    let userSession = await axios.get(config.API_URL + "/users/verify", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${window.localStorage.getItem("accessToken")}`,
      },
    });

    return userSession;
  } else {
    return {};
  }
};

function Login() {
  const { loggedIn } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();

  useEffect(() => {
    function checkUser() {
      if (loggedIn === 0) {
        checkUserSession().then(function (data) {
          if (data.data.user.length) {
            dispatch(callData(data.data.user[0]));
            dispatch(callLoggedIn(1));
          } else {
            dispatch(callLoggedIn(0));
            console.log("Not Authorized");
          }
        });
      }
    }
    checkUser();
  }, [dispatch, loggedIn]);

  const responseGoogle = async (googleData) => {
    let formData = new FormData();
    formData.append("token", googleData.credential);
    const res = await axios.post(
      config.API_URL + "/users/authcheck",
      { token: googleData.credential },
      {
        "Content-Type": "application/json",
      }
    );
    const data = res.data;
    if (data.status === true) {
      window.localStorage.setItem(
        "userData",
        JSON.stringify(googleData.profileObj)
      );
      window.localStorage.removeItem("accessToken");
      window.localStorage.setItem("accessToken", data.accessToken);
      dispatch(callLoggedIn(1));
      dispatch(callData(data.result[0]));
    } else {
      dispatch(callLoggedIn(0));
      dispatch(callData(null));
    }
  };

  return (
    <main className="login-main login-bg" style={{ opacity: 1 }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          width: "100vw",
          height: "100vh",
          justifyItems: "center",
          paddingTop: "200px",
        }}
      >
        <div style={{ flex: "1" }}></div>
        <div style={{ flex: "1" }} className="login-content">
          <div className="fs24 bold">
            COGNITIVE PIXEL&apos;s
            <br /> PERFORMANCE PORTAL
          </div>
          <p className="fs20 m0">
            For transparency within all aspects of your growth, performance, and
            presence at Cognitive Pixel.{" "}
          </p>
          <GoogleLogin 
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="login-gmail-button br4"
                disabled={renderProps.disabled}
              >
                <span className="fs20 bold">Login With Gmail</span>
              </button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
          />

          {/* <GoogleLogin
            clientId="394292041585-srbv27shrg8gm8c16begt7e75en79lp0.apps.googleusercontent.com"
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="login-gmail-button br4"
                disabled={renderProps.disabled}
              >
                <span className="fs20 bold">Login With Gmail</span>
              </button>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          /> */}
        </div>
      </div>
    </main>
  );
}

export default Login;
