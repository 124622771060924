import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import OuterComponent from './view/OuterPage/Outerpage';
import { Provider as ReduxProvider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ErrorBoundary from './view/component/errorboundry';
import { GoogleOAuthProvider } from '@react-oauth/google';


const theme = createTheme({
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
});

ReactDOM.render(
  <GoogleOAuthProvider clientId="394292041585-srbv27shrg8gm8c16begt7e75en79lp0.apps.googleusercontent.com">
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <ReduxProvider store={store}>
        <OuterComponent />
      </ReduxProvider>
    </ThemeProvider>
  </ErrorBoundary>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
